import React, { forwardRef } from "react"
import { Card } from "react-bootstrap"
import s from "../../patientFiles.module.scss"
import c from "classnames"
import { Study } from "../../../DicomViewer/types"
import { isStudy, ShareFile } from "../../../../services/ShareService/types"
import { useMediaQuery } from "react-responsive"
import CardContent from "./CardContent"

interface CollectionViewProps {
  currentRows: (Study | ShareFile)[]
  isFileDownloading: boolean
  handleImageError: (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => void
  handleViewForStudy: (studyId: Study["studyId"]) => void
  handleViewForFile: (file: ShareFile) => void
  downloadingIndex: number
  handleStudyDownload: (studyId: Study["studyId"], index: number) => void
  handleFileDownload: (
    link: ShareFile["link"],
    originalName: ShareFile["originalName"],
    index: number
  ) => void
}

export const CollectionView = forwardRef<HTMLDivElement, CollectionViewProps>(
  (
    {
      currentRows,
      handleImageError,
      handleViewForStudy,
      handleViewForFile,
      handleStudyDownload,
      handleFileDownload,
      isFileDownloading,
      downloadingIndex,
    },
    ref
  ) => {
    const isXSmallScreen = useMediaQuery({ query: "(max-width: 575px)" })

    return (
      <div ref={ref}>
        {currentRows.map((item, index) => (
          <Card
            className={c(s.collectionContainer, "p-3", "w-100", "mb-4")}
            key={isStudy(item) ? item.studyId : item.id}
          >
            <CardContent
              item={item}
              index={index}
              isFileDownloading={isFileDownloading}
              downloadingIndex={downloadingIndex}
              handleImageError={handleImageError}
              handleViewForStudy={handleViewForStudy}
              handleViewForFile={handleViewForFile}
              handleStudyDownload={handleStudyDownload}
              handleFileDownload={handleFileDownload}
              isXSmallScreen={isXSmallScreen}
            />
          </Card>
        ))}
      </div>
    )
  }
)
