import React, { useRef, useState, useEffect, useCallback } from "react"
import Button from "../Button"
import { Col, Modal, Pagination, Row } from "react-bootstrap"
import { Share, ShareFile } from "../../services/ShareService/types"
import { Instance, Study } from "../DicomViewer/types"
import shareService from "../../services/ShareService"
import { convertDateFormat } from "../../utils"
import { BaseView } from "../../pages/view"
import defaultImage from "../../assets/imgs/defaultFile.png"
import c from "classnames"
import { makeDicomUrl } from "../DicomViewer/DicomStack"
import { API_HOSTNAME } from "../../config"
import RenderHelp from "./RenderHelp"
import PaginationItems from "./PaginationItems"
import ResponsiveFileView from "./FileView/ResponsiveFileView"
import { useAppLocation } from "../../hooks"

interface PatientFilesListingProps {
  shareFiles: ShareFile[]
  isSmallScreen: boolean
}

export const getDicomImageUrl = (instance: Instance): string => {
  return makeDicomUrl(
    instance,
    "frames/1/rendered",
    `${API_HOSTNAME}api/share/dicom/`
  )
}

const PatientFilesListing: React.FC<PatientFilesListingProps> = ({
  shareFiles,
  isSmallScreen,
}) => {
  const [show, setShow] = useState(false)
  const location = useAppLocation()
  const share: Share = location.share ?? []
  const studies: Study[] = location.studies || []

  const [isAllFilesDownloading, setIsAllFilesDownloading] =
    React.useState(false)
  const [isFileDownloading, setIsFileDownloading] = React.useState(false)
  const [showViewer, setShowViewer] = React.useState(false)
  const [instances, setInstances] = useState<Instance[]>([])
  const [files, setFiles] = useState<ShareFile[]>([])

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [totalPages, setTotalPages] = useState(1)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const allFiles = [...studies, ...shareFiles] // Combine studies and files for pagination

  const downloadFiles = async () => {
    setIsAllFilesDownloading(true)

    await shareService.filesDownload({
      code: share.code,
      dob: convertDateFormat(share.dob),
    })

    setIsAllFilesDownloading(false)
  }

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const target = event.target as HTMLImageElement
    target.src = defaultImage
  }

  const handleStudyDownloadClick = async (studyId: Instance["studyId"]) => {
    setIsFileDownloading(true)
    await shareService.downloadStudy(studyId, share.code, share.dob)
    setIsFileDownloading(false)
  }

  const handleFileDownloadClick = async (
    link: ShareFile["link"],
    originalName: ShareFile["originalName"]
  ) => {
    setIsFileDownloading(true)
    await shareService.downloadFile(link, originalName)
    setIsFileDownloading(false)
  }

  // Dynamically calculate the number of rows that can fit in the table based on height
  useEffect(() => {
    const updateRowsPerPage = () => {
      if (tableContainerRef.current) {
        const containerHeight = tableContainerRef.current.clientHeight
        const rowHeight = 60
        const calculatedRows = Math.floor(containerHeight / rowHeight)
        setRowsPerPage(calculatedRows)
        setTotalPages(Math.ceil(allFiles.length / calculatedRows))
      }
    }

    updateRowsPerPage()
    window.addEventListener("resize", updateRowsPerPage)

    return () => window.removeEventListener("resize", updateRowsPerPage)
  }, [allFiles.length])

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleViewAllImages = () => {
    const allInstances = studies.flatMap((study: Study) => study.instances)
    setInstances(allInstances)
    setFiles(shareFiles ?? [])
    setShowViewer(true)
  }

  const handleViewForStudy = (studyId: string) => {
    const study = studies.find((study: Study) => study.studyId === studyId)
    if (study) {
      setInstances(study.instances)
      setFiles([])
      setShowViewer(true)
    }
  }

  const handleViewForFile = (file: ShareFile) => {
    setInstances([])
    setFiles([file])
    setShowViewer(true)
  }

  // Handle pagination
  const indexOfLastRow = currentPage * rowsPerPage
  const indexOfFirstRow = indexOfLastRow - rowsPerPage
  const currentRows = allFiles.slice(indexOfFirstRow, indexOfLastRow)

  const renderHelp = useCallback(
    () => (
      <RenderHelp
        isSmallScreen={isSmallScreen}
        show={show}
        handleShow={setShow}
      />
    ),
    [isSmallScreen, show]
  )

  return (
    <div
      className={c(
        {
          card: !isSmallScreen,
        },
        "shadow-sm",
        "border-0",
        "mb-1",
        "h-100"
      )}
    >
      <ResponsiveFileView
        isSmallScreen={isSmallScreen}
        currentRows={currentRows}
        handleImageError={handleImageError}
        handleViewForStudy={handleViewForStudy}
        handleViewForFile={handleViewForFile}
        handleStudyDownloadClick={handleStudyDownloadClick}
        handleFileDownloadClick={handleFileDownloadClick}
        isFileDownloading={isFileDownloading}
        ref={tableContainerRef}
      />
      {!isSmallScreen && <hr />}
      <Row className="mt-2 px-3">
        {!isSmallScreen && renderHelp()}
        <Col
          className={c("d-flex", "order-md-2", {
            "justify-content-center": isSmallScreen,
            "justify-content-end": !isSmallScreen,
          })}
          md={6}
          sm={12}
        >
          <Pagination>
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Pagination.Prev>
            <PaginationItems
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
            <Pagination.Next
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </Pagination.Next>
          </Pagination>
        </Col>
      </Row>
      <Row className="m-1">
        <Col md={6} className="mb-2 order-sm-2 order-md-1">
          <Button
            label={isAllFilesDownloading ? "" : "Download All Images"}
            className="dark-bg btn w-100"
            onClick={downloadFiles}
            disabled={isAllFilesDownloading}
            showSpinner={isAllFilesDownloading}
          />
        </Col>
        <Col md={6} className="mb-2 order-sm-1 order-md-2">
          <Button
            label="View All Images Online"
            className="w-100"
            variant="warning"
            onClick={handleViewAllImages}
          />
        </Col>
        {isSmallScreen && renderHelp()}
      </Row>
      <Modal show={showViewer} fullscreen onHide={() => setShowViewer(false)}>
        <BaseView
          instances={instances}
          files={files}
          isOhifActive={share.isOhifActive}
          ohifUrl={share.ohifUrl}
        />
      </Modal>
    </div>
  )
}

export default PatientFilesListing
