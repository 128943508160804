import { useLocation } from "react-router-dom"
import { pathList, PathListKeys } from "../routes/pathList"
import userService from "../services/UserService"
import { Share } from "../services/ShareService/types"
import { Study } from "../components/DicomViewer/types"

export interface AppState {
  share: Share
  studies?: Study[]
}

export function useUser() {
  return userService.user
}

export function useAppLocation() {
  const location = useLocation()
  return location.state as AppState
}
export function useMatchingPath(paths: PathListKeys[]): boolean {
  const location = useLocation()

  return (
    paths.find((pathKey) => {
      const rePath = pathList[pathKey].path.replace(/:[^/]+/g, "[^/]+")
      return location.pathname.match(rePath) !== null
    }) !== undefined
  )
}
