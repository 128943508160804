import React from "react"
import { Col, Spinner } from "react-bootstrap"
import pdfIcon from "../assets/icon/pdf-icon.png"
import downloadIcon from "../assets/imgs/downloadIcon.png"
import eyeIcon from "../assets/imgs/eyeIcon.png"
import s from "../patientFiles.module.scss"
import c from "classnames"
import { isStudy, ShareFile } from "../../../services/ShareService/types"
import { Study } from "../../DicomViewer/types"
import { getDicomImageUrl } from "../patientFiles"

export interface ItemProps {
  item: Study | ShareFile
  index: number
  isXSmallScreen?: boolean
  isFileDownloading: boolean
  downloadingIndex: number
  handleImageError: (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => void
  handleViewForStudy: (studyId: string) => void
  handleViewForFile: (file: ShareFile) => void
  handleStudyDownload: (studyId: string, index: number) => void
  handleFileDownload: (
    link: string,
    originalName: string,
    index: number
  ) => void
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  return date.toLocaleString([], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  })
}

const ItemContent = ({
  item,
  index,
  isXSmallScreen = false,
  isFileDownloading,
  downloadingIndex,
  handleImageError,
  handleViewForStudy,
  handleViewForFile,
  handleStudyDownload,
  handleFileDownload,
}: ItemProps) => {
  const isItemStudy = isStudy(item)

  const renderThumbnail = () => (
    <img
      className={c(s.thumbnail, { "mb-2": isXSmallScreen })}
      src={
        isItemStudy
          ? getDicomImageUrl(item.instances[0])
          : item.contentType?.split("/")[1].toLowerCase() === "pdf"
          ? pdfIcon
          : item.link
      }
      onError={handleImageError}
      alt="File Thumbnail"
    />
  )

  const renderDate = () =>
    isItemStudy
      ? item.date
        ? formatDate(item.date)
        : "Not Available"
      : "Not Available"

  const renderModality = () =>
    isItemStudy ? item.instances[0].modality || "N/A" : "N/A"

  const renderBodyPart = () =>
    isItemStudy ? item.bodyPart || "Not Available" : "Not Available"

  const renderDescription = () =>
    isItemStudy
      ? item.studyDescription || "Not Available"
      : item.contentType?.split("/")[1].toUpperCase() + " File"

  const renderFileCount = () => (isItemStudy ? item.count : 1)

  const renderActions = (isTable: boolean) => {
    const Tag = isTable ? "div" : Col

    return (
      <>
        <Tag>
          <img
            className="w-auto m-2"
            src={eyeIcon}
            alt="eye Icon"
            onClick={() =>
              isItemStudy
                ? handleViewForStudy(item.studyId)
                : handleViewForFile(item as ShareFile)
            }
          />
          <span>View</span>
        </Tag>
        <Tag>
          {downloadingIndex === index && isFileDownloading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="m-2"
            />
          ) : (
            <>
              <img
                className="w-auto m-2"
                src={downloadIcon}
                alt="Download Icon"
                onClick={() =>
                  isItemStudy
                    ? handleStudyDownload(item.studyId, index)
                    : handleFileDownload(
                        (item as ShareFile).link,
                        (item as ShareFile).originalName,
                        index
                      )
                }
              />
              <span>Download</span>
            </>
          )}
        </Tag>
      </>
    )
  }

  return {
    renderThumbnail,
    renderDate,
    renderModality,
    renderBodyPart,
    renderDescription,
    renderFileCount,
    renderActions,
  }
}

export default ItemContent
