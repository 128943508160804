import { Row, Col } from "react-bootstrap"
import ItemContent, { ItemProps } from "../ItemContent"
import c from "classnames"

const CardContent: React.FC<ItemProps & { isXSmallScreen: boolean }> = ({
  isXSmallScreen,
  ...props
}) => {
  const {
    renderThumbnail,
    renderDate,
    renderModality,
    renderBodyPart,
    renderDescription,
    renderFileCount,
    renderActions,
  } = ItemContent(props)

  const Tag = isXSmallScreen ? "span" : "p"

  const renderColumn = () => (
    <Col xs={12} sm={4}>
      <h6 className="m-0">
        <strong># Files</strong>
        <span> {renderFileCount()}</span>
      </h6>
    </Col>
  )

  return (
    <>
      <Row>
        <Col xs={12} sm={4}>
          {renderThumbnail()}
        </Col>
        <Col xs={12} sm={4} className={c({ "d-flex gap-1": isXSmallScreen })}>
          <h6>
            <strong>Study Date</strong>
          </h6>
          <Tag>{renderDate()}</Tag>
        </Col>
        <Col xs={12} sm={4} className={c({ "d-flex gap-1": isXSmallScreen })}>
          <h6>
            <strong>Body Part</strong>
          </h6>
          <Tag>{renderBodyPart()}</Tag>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={4} className={c({ "d-flex gap-1": isXSmallScreen })}>
          <h6>
            <strong>Modality</strong>
          </h6>
          <Tag>{renderModality()}</Tag>
        </Col>
        <Col xs={12} sm={4} className={c({ "d-flex gap-1": isXSmallScreen })}>
          <h6>
            <strong>Description</strong>
          </h6>
          <Tag>{renderDescription()}</Tag>
        </Col>
        {isXSmallScreen && renderColumn()}
      </Row>
      {isXSmallScreen && <hr />}
      <Row className="align-items-center">
        {!isXSmallScreen && renderColumn()}
        {renderActions(false)}
      </Row>
    </>
  )
}

export default CardContent
