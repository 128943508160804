import React, { useRef } from "react"
import s from "./patientFiles.module.scss"
import Button from "../Button"
import c from "classnames"
import { Col, Modal, Overlay, Popover } from "react-bootstrap"
import HelpContent from "./HelpContent"

interface props {
  isSmallScreen: boolean
  show: boolean
  handleShow: (flag: boolean) => void
}

const RenderHelp = ({ isSmallScreen, show, handleShow }: props) => {
  const target = useRef(null)

  return (
    <Col md={6} className="order-sm-3 order-md-1">
      <div
        className={`d-flex align-items-center ${
          isSmallScreen && "justify-content-center"
        }`}
      >
        {" "}
        <Button
          variant="link"
          ref={target}
          className="text-decoration-none font-size font-family px-1"
          onClick={() => handleShow(!show)}
          label="Help"
        />
        <p className="m-0">with Downloaded DICOM Files</p>
      </div>

      {isSmallScreen ? (
        <Modal show={show} onHide={() => handleShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Help</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <HelpContent
              onClose={() => handleShow(false)}
              isSmallScreenActive
            />
          </Modal.Body>
        </Modal>
      ) : (
        <Overlay target={target.current} show={show} placement="left-end">
          {(props) => (
            <Popover
              id="popover-positioned-top"
              {...props}
              className={c(s.popover)}
            >
              <Popover.Body className={c(s.popoverBody)}>
                <HelpContent onClose={() => handleShow(false)} />
              </Popover.Body>
            </Popover>
          )}
        </Overlay>
      )}
    </Col>
  )
}

export default RenderHelp
