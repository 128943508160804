import ItemContent, { ItemProps } from "../ItemContent"

const TableRowContent = (props: ItemProps) => {
  const {
    renderThumbnail,
    renderDate,
    renderModality,
    renderBodyPart,
    renderDescription,
    renderFileCount,
    renderActions,
  } = ItemContent(props)

  return (
    <>
      <td colSpan={2}>{renderThumbnail()}</td>
      <td colSpan={2}>{renderDate()}</td>
      <td colSpan={2}>{renderModality()}</td>
      <td colSpan={2}>{renderBodyPart()}</td>
      <td colSpan={3}>{renderDescription()}</td>
      <td colSpan={1}>{renderFileCount()}</td>
      <td colSpan={4}>
        <div className="d-flex justify-content-center align-items-center">
          {renderActions(true)}
        </div>
      </td>
    </>
  )
}

export default TableRowContent
