import React, { forwardRef } from "react"
import { Table } from "react-bootstrap"
import s from "../../patientFiles.module.scss"
import c from "classnames"
import { Study } from "../../../DicomViewer/types"
import { isStudy, ShareFile } from "../../../../services/ShareService/types"
import TableRowContent from "./TableRowContent"

interface TableViewProps {
  currentRows: (Study | ShareFile)[]
  isFileDownloading: boolean
  handleImageError: (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => void
  handleViewForStudy: (studyId: Study["studyId"]) => void
  handleViewForFile: (file: ShareFile) => void
  downloadingIndex: number
  handleStudyDownload: (studyId: Study["studyId"], index: number) => void
  handleFileDownload: (
    link: ShareFile["link"],
    originalName: ShareFile["originalName"],
    index: number
  ) => void
}

export const TableView = forwardRef<HTMLDivElement, TableViewProps>(
  (
    {
      currentRows,
      handleImageError,
      handleViewForStudy,
      handleViewForFile,
      handleStudyDownload,
      handleFileDownload,
      isFileDownloading,
      downloadingIndex,
    },
    ref
  ) => {
    return (
      <div className={c(s.tableContainer)} ref={ref}>
        <Table className={c(s.table, "w-100")}>
          <thead className="dark-bg text-center">
            <tr className="font-size">
              <th colSpan={2}>FILES</th>
              <th colSpan={2}>STUDY DATE</th>
              <th colSpan={2}>MODALITY</th>
              <th colSpan={2}>BODY PART</th>
              <th colSpan={3}>DESCRIPTION</th>
              <th colSpan={1}># FILES</th>
              <th colSpan={4} className="text-center">
                OPTIONS
              </th>
            </tr>
          </thead>
          <tbody className="font-size text-center">
            {currentRows.map((item, index) => (
              <tr
                className={s.tableRow}
                key={isStudy(item) ? item.studyId : item.id}
              >
                <TableRowContent
                  item={item}
                  index={index}
                  isFileDownloading={isFileDownloading}
                  downloadingIndex={downloadingIndex}
                  handleImageError={handleImageError}
                  handleViewForStudy={handleViewForStudy}
                  handleViewForFile={handleViewForFile}
                  handleStudyDownload={handleStudyDownload}
                  handleFileDownload={handleFileDownload}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
)
