import React from "react"
import { Pagination } from "react-bootstrap"

interface Props {
  currentPage: number
  totalPages: number
  handlePageChange: (pageNumber: number) => void
}

// Render pagination with ellipsis
const PaginationItems = ({
  currentPage,
  totalPages,
  handlePageChange,
}: Props) => {
  const items = []
  if (totalPages <= 5) {
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      )
    }
  } else {
    items.push(
      <Pagination.Item
        key={1}
        active={1 === currentPage}
        onClick={() => handlePageChange(1)}
      >
        1
      </Pagination.Item>
    )
    if (currentPage > 3)
      items.push(<Pagination.Ellipsis key="start-ellipsis" />)

    const startPage = Math.max(2, currentPage - 1)
    const endPage = Math.min(totalPages - 1, currentPage + 1)

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      )
    }

    if (currentPage < totalPages - 2)
      items.push(<Pagination.Ellipsis key="end-ellipsis" />)

    items.push(
      <Pagination.Item
        key={totalPages}
        active={totalPages === currentPage}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    )
  }

  return <>{items}</>
}

export default PaginationItems
