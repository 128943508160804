import React, { forwardRef } from "react"
import { ShareFile } from "../../../services/ShareService/types"
import { CollectionView } from "./CollectionView"
import { TableView } from "./TableView"
import { Instance, Study } from "../../DicomViewer/types"

interface ResponsiveFileViewProps {
  isSmallScreen: boolean
  currentRows: (Study | ShareFile)[]
  handleImageError: (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => void
  handleViewForStudy: (studyId: Instance["studyId"]) => void
  handleViewForFile: (file: ShareFile) => void
  handleStudyDownloadClick: (studyId: Instance["studyId"]) => void
  handleFileDownloadClick: (link: string, originalName: string) => void
  isFileDownloading: boolean
}

const ResponsiveFileView = forwardRef<HTMLDivElement, ResponsiveFileViewProps>(
  (
    {
      isSmallScreen,
      currentRows,
      handleImageError,
      handleViewForStudy,
      handleViewForFile,
      handleStudyDownloadClick,
      handleFileDownloadClick,
      isFileDownloading,
    },
    ref // forward the ref here
  ) => {
    const [downloadingIndex, setDownloadingIndex] = React.useState(0)

    const handleStudyDownload = (
      studyId: Instance["studyId"],
      index: number
    ) => {
      setDownloadingIndex(index)
      handleStudyDownloadClick(studyId)
    }

    const handleFileDownload = (
      link: ShareFile["link"],
      originalName: ShareFile["originalName"],
      index: number
    ) => {
      setDownloadingIndex(index)
      handleFileDownloadClick(link, originalName)
    }

    return isSmallScreen ? (
      <CollectionView
        currentRows={currentRows}
        handleImageError={handleImageError}
        handleViewForStudy={handleViewForStudy}
        handleViewForFile={handleViewForFile}
        handleStudyDownload={handleStudyDownload}
        handleFileDownload={handleFileDownload}
        isFileDownloading={isFileDownloading}
        downloadingIndex={downloadingIndex}
        ref={ref} // pass the ref down
      />
    ) : (
      <TableView
        currentRows={currentRows}
        handleImageError={handleImageError}
        handleViewForStudy={handleViewForStudy}
        handleViewForFile={handleViewForFile}
        handleStudyDownload={handleStudyDownload}
        handleFileDownload={handleFileDownload}
        isFileDownloading={isFileDownloading}
        downloadingIndex={downloadingIndex}
        ref={ref} // pass the ref down
      />
    )
  }
)

export default ResponsiveFileView
